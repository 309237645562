import React, { useState } from "react";

function Healthform(props) {

    const [answer , setAnswer] = useState();
    const [answerA , setAnswerA] = useState();
    const [answerB , setAnswerB] = useState();
    const [answerC , setAnswerC] = useState();

    const handleSubmit = (e)=>{
        e.preventDefault();
        const data = {
            answer:answer,
            answerA:answerA,
            answerB:answerB,
            answerC:answerC,
        }
        console.log(data);
        try {
            
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <div id="__healthform" className="modal"  >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Fill this Form</h4>
                            <button type="button" className="document-close" data-dismiss="modal" >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="health-are">
                                <div className="container health">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <form className="">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-labels">Are you Able to Walk on ground ?</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" name="answer" value={answer} onChange={(e)=>setAnswer(e.target.value)} aria-describedby="emailHelp" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-labels">Are you able to get in and out of Bed ?</label>
                                                    <input type="text" className="form-control" name="answerA" value={answerA} onChange={(e)=>setAnswerA(e.target.value)}  id="exampleInputPassword1" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-labels">Does your health now limit you to do Work ?</label>
                                                    <input type="text" className="form-control" name="answerB" value={answerB} onChange={(e)=>setAnswerB(e.target.value)}  id="exampleInputPassword1" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-labels">Explain in Brief</label>
                                                    <textarea className="form-control" name="answerC" value={answerC} onChange={(e)=>setAnswerC(e.target.value)}  id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                                <button type="button" className="btn-small btn-purple w-30" onClick={handleSubmit}>Send Message</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Healthform;