import React, { useState, useEffect } from 'react';
import './custom.css';
import Section from './layouts/Section';
import TaskList from './Task/TaskList';
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import auth from '../api/auth';
import { connect } from 'react-redux';
import axios from 'axios';

function Task(props) {

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { user } = props;
    const userId = user ? user.oid : null;

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        const getTasks = async () => {
            const token = window.localStorage.getItem('AUTH_TOKEN');
            try {
                const response = await axios.get(`https://live.avertisystems.com/api/v1/tasks/${userId}`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application json',
                    },
                });
                setTasks(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.data && error.response.data.message === 'Unauthenticated.') {
                    setError('You are not authenticated. Please log in.');
                } else {
                    setError('API request error');
                    console.error("API request error:", error);
                }
            }
        };

        getTasks();
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }



    return (
        <div className='' style={{ height: "100vh" }}>
            <Section allNotification={false} searchPopup={true} title={'Task'}>
                <div style={{marginTop:"60px"}}>
                    {<TaskList tasks={tasks} />}
                </div>
            </Section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.userDetail,
})

export default connect(mapStateToProps)(Task);