import React, { Fragment, useEffect, useState } from "react";
import Section from "./layouts/Section";
import "./prashant.css";
import AvertiLoader from "../loader";
import "./prashant.css";
import auth from "../api/auth";
import CashAudioModal from "./cash flow/Modal/CashAudioModal";
import CashPdfModal from "./cash flow/Modal/CashPdfModal";
import CashReportPdf from "./cash flow/Modal/CashReportPdf";
import CashVideoModal from "./cash flow/Modal/CashVideoModal";
import moment from "moment/moment";
import CashflowModel from "./cashflowModel";
import CommentModel from "./cash flow/Modal/CommentModel";

function CashFlowReport() {
    const [loader] = AvertiLoader();
    const [posts, setPosts] = useState([]);

   const month = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		const d = new Date();
		const currentMonth = (new Date().getMonth() + 1).toString();
		const [selectedValues, setSelectedValues] = useState(currentMonth);
		const [isOpen, setIsOpen] = useState(month[d.getMonth()]);

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const endYear = currentYear;
    const years = Array.from(
        { length: endYear - startYear + 1 },
        (_, index) => startYear + index,
    );

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    const getReports = async () => {
        const token = window.localStorage.getItem("AUTH_TOKEN");
        const response = await auth.get(
            `/reports/show/cashflow/${selectedYear}/${selectedValues}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "Application/json",
                    Accept: "Application/json",
                },
            },
        );
        setPosts(response.data.data);

    };
    useEffect(() => {
        getReports();
    }, [selectedYear, selectedValues]);

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };
    const handleToggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
			<>
				{loader}
				<Section
					allNotification={false}
					searchPopup={true}
					title={"Cash Flow Report"}
				/>
				<div style={{ marginBottom: "50px", marginTop: "60px" }}>
					<div className="cart-area" id="_report_css">
						<div
							className={`container ${isOpen ? "modal-open" : "modal-closed"}`}
						>
							{isOpen ? (
								<div className="buttons_cross">
									<select
										id="yearSelector"
										className="year_btn"
										onChange={handleYearChange}
									>
										<option value="">{selectedYear}</option>
										{years.map((year) => (
											<option key={year} value={year}>
												{year}
											</option>
										))}
									</select>
									<i className="bi bi-x _xicon" onClick={handleToggleModal}></i>
								</div>
							) : (
								<div className="buttons_dot">
									<i
										className="bi bi-filter _dicon"
										onClick={handleToggleModal}
									></i>
								</div>
							)}
						</div>
						<div className="container">
							<div className="col-12">
								<div className="radiogroup_1">
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											id="a"
											checked={selectedValues === "1"}
											value="1"
										/>
										<label className="label_m" htmlFor="a">
											<div className="indicator">
												<span className="text">JAN</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											id="b"
											checked={selectedValues === "2"}
											value="2"
										/>
										<label className="label_m" htmlFor="b">
											<div className="indicator">
												<span className="text">FEB</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											id="c"
											checked={selectedValues === "3"}
											value="3"
										/>
										<label className="label_m" htmlFor="c">
											<div className="indicator">
												<span className="text">MAR</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											checked={selectedValues === "4"}
											id="d"
											value="4"
										/>
										<label className="label_m" htmlFor="d">
											<div className="indicator">
												<span className="text">APR</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											id="e"
											checked={selectedValues === "5"}
											value="5"
										/>
										<label className="label_m" htmlFor="e">
											<div className="indicator">
												<span className="text">MAY</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											id="f"
											value="6"
											checked={selectedValues === "6"}
										/>
										<label className="label_m" htmlFor="f">
											<div className="indicator">
												<span className="text">JUNE</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											id="g"
											checked={selectedValues === "7"}
											value="7"
										/>
										<label className="label_m" htmlFor="g">
											<div className="indicator">
												<span className="text">JULY</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											checked={selectedValues === "8"}
											id="h"
											value="8"
										/>
										<label className="label_m" htmlFor="h">
											<div className="indicator">
												<span className="text">AUG</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											onChange={(e) => setSelectedValues(e.target.value)}
											type="radio"
											name="app"
											checked={selectedValues === "9"}
											id="i"
											value="9"
										/>
										<label className="label_m" htmlFor="i">
											<div className="indicator">
												<span className="text">SEPT</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											type="radio"
											name="app"
											id="j"
											checked={selectedValues === "10"}
											value="10"
											onChange={(e) => setSelectedValues(e.target.value)}
										/>
										<label className="label_m" htmlFor="j">
											<div className="indicator">
												<span className="text">OCT</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											type="radio"
											name="app"
											id="k"
											checked={selectedValues === "11"}
											value="11"
											onChange={(e) => setSelectedValues(e.target.value)}
										/>
										<label className="label_m" htmlFor="k">
											<div className="indicator">
												<span className="text">NOV</span>
											</div>
										</label>
									</div>
									<div className="wrapper">
										<input
											className="state"
											type="radio"
											name="app"
											id="l"
											checked={selectedValues === "12"}
											value="12"
											onChange={(e) => setSelectedValues(e.target.value)}
										/>
										<label className="label_m" htmlFor="l">
											<div className="indicator">
												<span className="text">DEC</span>
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container">
						{posts &&
							posts.map((list, index) => (
								<Fragment key={index}>
									<div className=" mb-2 _reportbgsha">
										<p
											id="p_cashflow"
											className="mb-2"
											style={{ paddingLeft: "10px" }}
										>
											{moment(list.created_at).format("D/MM/YYYY")}
										</p>
										<div className="d-flex mb-3 p-3 bg-white justify-content-between align-items-center rounded-4">
											<div className="_report_name">
												{list.comment.substr(0, 22)}
											</div>
											<div className="d-flex _icon_button">
												<CommentModel list={list} />
												<CashPdfModal list={list} />
												<CashflowModel list={list} />
												<CashReportPdf
													selectedYear={selectedYear}
													list={list}
												/>
												<CashAudioModal list={list} />
												<CashVideoModal list={list} />
											</div>
										</div>
									</div>
								</Fragment>
							))}
					</div>
				</div>

				<div className="container" style={{ height: "20px" }}></div>
			</>
		);
}

export default CashFlowReport;
