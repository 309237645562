import axios from 'axios'
const BASE_URL = process.env.REACT_APP_BASE_URL;
const NEW_MAIN_URL = process.env.REACT_APP_MAIN_URL;

export const SITE_URL = 'https://live.avertisystems.com';

const auth = axios.create({
    baseURL:BASE_URL,
})

auth.interceptors.response.use((response) => response, (error) => {
    if(error.response.data.message == 'Unauthenticated.') {
        if(window.location.pathname !== '/login'){
            window.location.pathname = '/login'
        }
    }
    throw error;
});

export default auth