import "../finance/finances.css";
import React from "react";
import Section from "../layouts/Section";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import "../prashant.css";
import auth from "../../api/auth";
import moment from "moment";


export default class Finances extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Bitcoin",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 50, 65],
        },
        {
          name: "Ethereum",
          data: [5, 21, 15, 22, 39, 72, 99, 50, 20, 55],
        },
        {
          name: "Binance",
          data: [15, 31, 32, 24, 65, 45, 58, 71, 50, 30],
        },
      ],
      options: {
        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Current Portfolio",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      transactions: [],
    };
  }

  componentDidMount() {
    this.getTransactions();
  }

  async getTransactions() {
    try {
      const res = await auth.get("/getTransactionsData", {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("AUTH_TOKEN")}`,
          Accept: "application/json",
        },
      });

      if (res.data.errorCode === 0) {
        this.setState({
          transactions: res.data.data,
        });
      }
    } catch (e) {
      console.error("Transactions", e);
    }
  }

  render() {
    const { transactions } = this.state;

    return (
      <div className="">
        <Section
          allNotification={false}
          searchPopup={true}
          title={"Finances"}
        />

        <div className="history-area">
          <div className="container">
            <div className="section-title">
              <h5 className="fina_nce title">Finance History</h5>
            </div>
            <div className="ba-history-inner">
              <div className="row custom-gutters-20">
                <div className="col-6">
                  <div
                    className="ba-single-history ba-single-history-one"
                    style={{ backgroundImage: "url(assets/img/bg/3.png)" }}
                  >
                    <h6>Income</h6>
                    <h5 className="finance-amount">$58,968.00</h5>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="ba-single-history ba-single-history-two"
                    style={{ backgroundImage: "url(assets/img/bg/3.png)" }}
                  >
                    <h6>Expenses</h6>
                    <h5>$50,968.00</h5>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="ba-single-history ba-single-history-three"
                    style={{ backgroundImage: "url(assets/img/bg/3.png)" }}
                  >
                    <h6>Total Bills</h6>
                    <h5>$50,968.00</h5>
                  </div>
                </div>
                <div className="col-6">
                  <div
                    className="ba-single-history ba-single-history-four"
                    style={{ backgroundImage: "url(assets/img/bg/3.png)" }}
                  >
                    <h6>Savings</h6>
                    <h5>$58.00</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="reactapexchart">
          <div className="container">
            <div className="reactapexchart-heading">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height={350}
              />
            </div>
          </div>
        </div>

        <div style={{ height: "20px" }}></div>

        <div className="container">
          <h5 className="fina_nce">Transactions</h5>
          {transactions.map((transaction) => (
            <React.Fragment key={transaction}>
              <div className="single-goal single-goal-one">
                <div className="row">
                  <div className="col-3 pr-0">
                    <div className="details-date">
                      <h6 className="pt-0">
                        {moment(transaction.date).format("DD")}{" "}
                      </h6>
                      {/* <p className="fin-tdate">{moment(transaction.date).format("MMM")}</p> */}
                    </div>
                  </div>
                  <div className="col-7 px-0">
                    <div style={{ paddingTop: "10px" }} className="details add">
                      <h6 className="pt-0">{transaction.name}</h6>
                      {/* <p style={{fontSize:'12px'}}>{transaction.category.join(', ') }</p> */}
                    </div>
                  </div>
                  <div className="col-2 px-0">
                    <div className="circle-inner circle-inner-one mt-4">
                      {transaction.amount > 0 ? (
                        <h6
                          style={{ color: "red" }}
                          className="goal-amount pt-0"
                        >
                          ${transaction.amount}
                        </h6>
                      ) : (
                        <h6 className="goal-amount pt-0">
                          ${Math.abs(transaction.amount)}
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="transaction-area">
          <div className="container">
            <div className="section-title">
              <h5 className="fina_nce title">‘Last Transaction</h5>
              <Link to={"/cashflow"}>View All</Link>
            </div>
            <ul className="_finance-transaction">
              <li className="ba-single-transaction style-two">
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/icon/3.png"}
                    alt="img"
                  />
                </div>
                <div className="details">
                  <h5>Addidas</h5>
                  <p>Purchase Shoes</p>
                  <h5 className="spend">-$130</h5>
                </div>
              </li>
              <li className="ba-single-transaction style-two">
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/icon/7.png"}
                    alt="img"
                  />
                </div>
                <div className="details">
                  <h5>Apple Inc.</h5>
                  <p>Investment</p>
                  <h5 className="invest">$500</h5>
                </div>
              </li>

              <li className="ba-single-transaction style-two">
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/icon/6.png"}
                    alt="img"
                  />
                </div>
                <div className="details">
                  <h5>Tesla Inc.</h5>
                  <p>Investment</p>
                  <h5 className="invest">$1300</h5>
                </div>
              </li>

              <li className="ba-single-transaction style-two">
                <div className="thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/img/icon/7.png"}
                    alt="img"
                  />
                </div>
                <div className="details">
                  <h5>Starbucks</h5>
                  <p>Purchase Coffee</p>
                  <h5 className="spend">-$130</h5>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div style={{ height: "50px" }}></div>
      </div>
    );
  }
}
