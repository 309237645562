import React, { Component } from "react";
// import { connect } from "react-redux";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";

import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
// import RechargeList from "./subcomponent/payment/RechargeList";
import Skyflow from "../skyflow";

class Addbalance extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userDetail: JSON.parse(localStorage.getItem('userDetail'))
		}
	}
	render() {
		return (
			<>
				{/* Navigation Bar Imported from Layouts */}
				<Navbar />

				{/* Header Starts */}
				<div id="header-section" className="header-area">
					<div className="container">
						<div className="row">
							<div className="col-sm-4 col-3">
								<div className="menu-bar">
									<i className="fa fa-bars" />
								</div>
							</div>
							<div className="col-sm-4 col-4 text-center">
								<Link to={'/'} className="logo">
									<img src={process.env.PUBLIC_URL + '/assets/img/averti2.png'} alt="logo" />
								</Link>
							</div>
							<div className="col-sm-4 col-5 text-right">
								<ul className="header-right">
									<li>
										<Link to={'/'}>
											<i className="fa fa-envelope" />
											<span className="badge">9</span>
										</Link>
									</li>
									<li>
										<Link to={'/notification'}>
											<i className="fa fa-bell animated infinite swing" />
											<span className="badge">6</span>
										</Link>
									</li>
									<li>
										<Link id="header-user" className="header-user" to={'/user-setting'}>
											<img src={this.state.userDetail?.profile_image ? this.state.userDetail?.profile_image : process.env.PUBLIC_URL + '/assets/img/user.png'} alt="img" width={25} height={25} />
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* Header Ends */}

				
				{/* Navbar Ends */}

				<div className="add-balance-area pd-top-40">
					<div className="container">
                        <div className="section-title style-three align-items-center d-flex justify-content-between mb-0">
                            <h3 className="title">Add Balance</h3>
                        </div>
						<Skyflow />
					</div>
				</div>

				{/* Footer Component Imported from Layouts */}
				<Footer />
			</>
		);
	}
}


export default (Addbalance);