import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";

import { addEvent, fetchEvents } from '../actions';
import AvertiLoader from "../loader";

function CalendarEventModal(props) {

    const eventFormRef = useRef();
    const [calendarLoader, showCalendarLoader, hideCalendarLoader] = AvertiLoader()

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [stime] = useState();
    const [etime] = useState();

    const saveEvent = (e) => {
        const form = eventFormRef.current;
        let formError = false;

        if (form.title.value === '') {
            formError = true;
            window.toastr.error('Please enter event name');
            form.title.focus();
        }

        if (form.sdate.value === '') {
            formError = true;
            window.toastr.error('Please choose a date');
            form.sdate.focus();
        }

        if (!formError) {

            showCalendarLoader()

            const requestData = {
                title: form.title.value,
                description: form.description.value,
                location: form.location.value,
                type: 1,
                start_time: date.toString() + ' ' + (form.stime.value !== '' ? form.stime.value : '00:00') + ':00',
                end_time: date.toString() + ' ' + (form.etime.value !== '' ? form.etime.value : '23:59') + ':00',
                user_id: null,
            }

            requestData.start_time = moment.utc(new Date(requestData.start_time))
            requestData.end_time = moment.utc(new Date(requestData.end_time))

            const savebutton = window.jQuery('#save-event')
            savebutton.attr('disabled', true)
            savebutton.html('Please wait ...')

            props.addEvent(requestData).then((data) => {
                window.toastr.success('Your event has been created successfully')
                form.reset()
                savebutton.attr('disabled', false)
                savebutton.html('Save')
                window.jQuery('#modal-centered').modal('hide')
                props.fetchEvents()
                hideCalendarLoader()
            })
                .catch((error) => {
                    window.toastr.error('Something went wrong. Please check and submit your data again!')
                    savebutton.attr('disabled', false)
                    savebutton.html('Save')
                    hideCalendarLoader()
                })
        }

    }

    const handleChange = (e) => {
        setDate(e.target.value);
    }

    const handleStartTime = (e) => {
        // console.log(e.target.value);
        // setStartTime(e.target.value);
    }

    const handleEndTime = (e) => {
        // setEndTime(e.target.value);
    }

    return (
        <Fragment>
            {calendarLoader}
            <div id="modal-centered" className="modal fade">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <b className="create_event" style={{ fontSize: '22px' }}>Create an Event</b>
                            <button type="button" className="closes btn-danger" onClick={() => window.jQuery('#modal-centered').modal('hide')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form ref={eventFormRef} className="form-horizontal">
                                <div className="row mb-2">
                                    <label htmlFor="event_title" className="label_event col-xs-4 col-4">Title:</label>
                                    <div className="col-xs-8 col-8">
                                        <input type="text" className="form-control event_input" id="event_title" name="title" placeholder="Event event name here" />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-4 col-4" htmlFor="stime">Start Time</label>
                                    <div className="col-xs-8 col-8">
                                        <input type="time" id="stime" className="form-control event_input" name="stime" value={stime} onChange={handleStartTime} />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-4 col-4" htmlFor="etime">End Time</label>
                                    <div className="col-xs-8 col-8">
                                        <input type="time" id="etime" name="etime" value={etime} onChange={handleEndTime} className="form-control event_input" />
                                    </div>
                                </div>
                                <div className="row  mb-5">
                                    <label className="label_event col-xs-4 col-4" htmlFor="sdate">Select Date</label>
                                    <div className="col-xs-8 col-8">
                                        <input type="date" id="sdate" name="sdate" value={date} onChange={handleChange} className="form-control event_input" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <input type="text" className="form-control event_input" name="location" placeholder="Location here ..." />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <div className="col-xs-12 col-12">
                                        <textarea className="form-control event_input" id="description" name="description" rows="5" placeholder="Enter event note here..."></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button onClick={saveEvent} type="button" className="btn button-save" id="save-event">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(null, { addEvent, fetchEvents })(CalendarEventModal);