import React, { useEffect, useState } from "react";
import Time from "./Time";
import Location from "./Location";
import Calendar from "react-calendar";
import CalendarEventModal from "./CalendarEventModal";
import DateView from "./DateView";
import { fetchEvents } from "../actions";
import moment from "moment";
import { connect } from "react-redux";
import Section from "./layouts/Section";
import Modal from "react-bootstrap/Modal";
import AvertiLoader from "../loader";
import { Spinner } from "react-bootstrap";

function EventCalendar(props) {

  const { events, loader } = props;

  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState({
    open: false,
    event: null,
  });

  const today = moment(date).format("YYYY-MM-DD");
  const todayMoment = moment(date, "YYYY-MM-DD");

  const currentEvents = events.filter((event) => {
    return (
      today === moment(event.start_time).format("YYYY-MM-DD") ||
      today === moment(event.end_time).format("YYYY-MM-DD") ||
      todayMoment.isBetween(
        moment(event.start_time, "YYYY-MM-DD"),
        moment(event.end_time, "YYYY-MM-DD")
      )
    );
  });

  const showDetails = (event) => {
    const _show = {
      open: true,
      event: event,
    };
    setShow(_show);
  };

  useEffect(() => {
    props.fetchEvents();
  }, []);


  return (
    <div className="event-calendar-wrapper">
      {loader ? <div className="fix-loader"><Spinner /></div> : ''}
      {/* Body content are started from here */}
      <Section
        allNotification={false}
        searchPopup={false}
        title={"Event Calendar"}
      />

      <div className="calendar-area">
        <div className="container calendar-title">
          <>
            <div className="container __calendarHeading">
              <h5 className="calendar-heading5">My Schedule</h5>
              <div className="__calendarContent">
                <button
                  type="button"
                  className="addevent-calendar"
                  onClick={(e) => { e.preventDefault(); window.jQuery('#modal-centered').modal('show') }}
                >
                  <i className="fa fa-plus plus-icon" aria-hidden="true"></i>Add Event
                </button>
                <CalendarEventModal />
              </div>
              <div className="calender-paragraph __calendarContent">
                <Time className="eventime" />
                <i className="fa fa-map-marker" aria-hidden="true">
                  <Location />
                </i>
              </div>
            </div>
            <div id="calender-section">
              <Calendar
                className="calendar_react"
                onClickDay={(d) => setDate(d)}
                value={date}
                onChange={(d) => setDate(d)}
                formatDay={(locale, date) => (
                  <DateView date={date} events={events} />
                )}
              />
            </div>

            {currentEvents.map((data, i) => {
              return (
                <div
                  onClick={(e) => showDetails(data)}
                  style={{ cursor: "pointer" }}
                  key={data.id}
                  className={"calender-item myevent cal-" + (i % 5)}
                >
                  <div className="main-row calender-row">
                    <div className="health-area-2-row1">
                      <p className="previous-meeting2">{data.title}</p>
                    </div>
                    {data.participants.length > 0 ? (
                      <>
                        <div className="health-area-2-row2">
                          <div className="health-section-area">
                            <img
                              src={data.image}
                              alt="img"
                              width="40"
                              className="avatar"
                            />
                            <p className="advisorname">{data.to}</p>
                          </div>
                        </div>
                        <div className="health-area-2-row1">
                          <p className="previous-meeting">
                            {data.participants[0]["first_name"]}
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="row health-area-2-row">
                      <div className="col-6">
                        <p className="previous-meeting">
                          {moment.utc(data.start_time).local().format("LTS")}
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="previous-meeting previous-meeting1">
                          {moment
                            .utc(data.start_time)
                            .local()
                            .format("ddd, ll")}
                        </p>
                      </div>
                    </div>
                    <div className="health-area-2-row2 area2-row-2">
                      <p className="previous-meeting">
                        <span className="previous-meeting1">
                          {" "}
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                        </span>
                        {data.location}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div style={{ height: "50px" }}></div>
          </>
        </div>
      </div>
      {show.open ? (
        <Modal
          show={show.open}
          fullscreen={true}
          onHide={() => setShow({ ...show, ["open"]: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{show.event.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{show.event.description}</p>
            <p>
              <i className="fa fa-map-marker"></i> {show.event.location}
            </p>
            <p>
              <i className="fa fa-clock-o"></i>{" "}
              {moment.utc(show.event.start_time).local().format("LLL")} -{" "}
              {moment.utc(show.event.end_time).local().format("LLL")}
            </p>
            {show.event.comment !== null ? (
              <p>
                <i className="fa fa-comment-o"></i> {show.event.comment}
              </p>
            ) : (
              ""
            )}
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loader: state.event.loader,
  events: state.event.events,
});

export default connect(mapStateToProps, { fetchEvents })(EventCalendar);
