import { EVENTS_ERRORS, FETCH_EVENTS, ADD_ERROR, ADD_EVENT } from "../store/redux-event-constant"
import { GET_MEETING_LIST, GET_MEETING_LIST_ERROR, LOADER } from "../store/redux-constant"
const initialState = {
	events: [],
	list: [],
	event: {},
	error: false,
	errorMessage: '',
	loader: false
}

const eventReducers = (state = initialState, action) => {

	switch (action.type) {
		case LOADER:
			return { ...state, loader: true }
		case FETCH_EVENTS:
			return { ...state, loader: false, events: action.payload }
		case EVENTS_ERRORS:
			return {
				...state,
				error: true,
				loader: false,
				errorMessage: 'getting errors in events'
			}
		case ADD_EVENT:
			return { ...state, loader: false, event: action.payload }
		case ADD_ERROR:
			return {
				...state,
				error: true,
				loader: false,
				errorMessage: 'getting errors in add event'
			}
		case GET_MEETING_LIST:
			return { ...state, loader: false, list: action.payload }
		case GET_MEETING_LIST_ERROR:
			return {
				...state,
				error: true,
				loader: false,
				errorMessage: "getting error in meeting list"
			}

		default:
			return state
	}
}

export default eventReducers