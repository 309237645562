import React, {useEffect} from "react";
import Section from "./layouts/Section";
import {Link} from "react-router-dom";
import './custom.css';
import { connect } from "react-redux";
import { getNotification } from '../actions/notification';

function Notification (props) {

    const {count, notifications} = props;

    useEffect(() =>{
        props.getNotification()
    }, [])

    const getRoute = (type) => {
        switch (type) {
            case 'event':
                return 'event-calendar';
            case 'message':
                return 'messages';
            default:
                break;
        }
    }

    return (
        <Section allNotification={false} searchPopup={true} title={'Notification'}>
            <div className="transaction-area pd-top-20 mg-bottom-40 " id="notifications">
                <div className="container notification-section">
                    <div className="section-title">
                        <h3 className="title">Inbox Notifications</h3>
                        <Link to={'/notification'}><i className="fa fa-bell" /></Link>
                    </div>
                    <div className="btn-wrap-area mb-2 text-center">
                        {/* <Link className="btn btn-red mr-2 mb-1" to={'/notification'}>Message 12</Link> */}
                        <Link className="btn btn-purple ml-2 mb-1" to={'/notification'}>Notification {count}</Link>
                    </div>
                    { notifications.data && notifications.data.map((notification) => 
                    <div key={notification.id} className="ba-bill-pay-inner">
                        <div className="ba-single-bill-pay">
                            <div className="thumb">
                                <img src={process.env.PUBLIC_URL + '/assets/img/icon/6.png'} alt="img" />
                            </div>
                            <div className="details">
                                <h5 className="noti-title">{notification.title}</h5>
                                <p className="noti-para">{notification.subtitle}</p>
                            </div>
                        </div>
                        <div className="amount-inner">
                            <h5><i className="fa fa-long-arrow-left no-arrow" /></h5>
                            <Link className="btn btn-blue" to={getRoute(notification.type)}>Read</Link>
                        </div>
                    </div>
                    )}
                    
                </div>
            </div>
        </Section>
    );
}

const mapStateToProps = (state) => ({
    notifications: state.notification.notifications,
    count: state.notification.count
})

export default connect(mapStateToProps, {getNotification}) (Notification)