import React,{useState} from "react";
import ReactApexChart from "react-apexcharts";

function AnnualExpensesDetail(props) {
    const { post } = props;
    
        const [expense, setExpense] = useState({
					series: [
						{
							name: "Expense",
							data: Object.values(post).map((item) => item.expense),
						},
					],
					options: {
						chart: {
							type: "bar",
							toolbar: {
								show: false,
							},
						},
						plotOptions: {
							bar: {
								horizontal: false,
								columnWidth: "55%",
								endingShape: "rounded",
							},
						},
						colors: ["#8884d8", "#82ca9d"],
						markers: {
							size: 8,
						},
						legend: {
							position: "top",
						},
						xaxis: {
							categories: [
								"JAN",
								"FEB",
								"MAR",
								"APR",
								"MAY",
								"JUN",
								"JUL",
								"AUG",
								"SEP",
								"OCT",
								"NOV",
								"DEC",
							],
						},
						yaxis: {
							title: {
								data: Object.values(post).map((item) => item.expense),
							},
						},
						fill: {
							opacity: 1,
						},
						tooltip: {
							y: {
								formatter: function (val) {
									return "$ " + val + " ";
								},
							},
						},
					},
				});
    
    
	return (
		<div>
			<h5>Annual Expenses Details</h5>
			<hr />
			<ReactApexChart
				options={expense.options}
				series={expense.series}
				type="bar"
				height={200}
			/>
		</div>
	);
}

export default AnnualExpensesDetail;
