import React, { useState, useEffect } from 'react';
import '../custom.css';
import { Modal, ModalHeader, ModalBody } from "react-bootstrap";
import { Fragment } from 'react';
import auth from '../../api/auth';
import { connect } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Document/Custom-toastify.css';


function TaskList(props) {

    const [open, setOpen] = useState(false);
    const [task, setTask] = useState(null);
    const { user, userDetail } = props;


    const handleOpen = (task) => {
        setTask(task);
        getComment(task.id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { tasks } = props;

    function formatTime(time) {
        const [hours, minutes] = time.slice(11, 16).split(':');
        const formattedHours = parseInt(hours);
        const amPm = formattedHours >= 12 ? 'PM' : 'AM';
        const displayHours = formattedHours > 12 ? formattedHours - 12 : formattedHours;
        return `${displayHours}:${minutes} ${amPm}`;
    }

    const [comment, SetComment] = useState([]);
    const [reload, setReload] = useState(false);
    const [comments, setComments] = useState([]);

    const getComment = async (id) => {
        setReload(true);
        const token = window.localStorage.getItem('AUTH_TOKEN');
        const response = await axios.get(`https://live.avertisystems.com/api/v1/task_comment/get/${id}`, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'Application/json',
                'Accept': 'Application/json',
            },
        });
        SetComment(response.data.data);
        if (response.data.data) {
            setOpen(true);
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = window.localStorage.getItem('AUTH_TOKEN');

        if (task === null) {
            return;
        }
        try {
            const response = await axios.post(`https://live.avertisystems.com/api/v1/task_comment/store`, {
                comment: comments,
                task_id: task.id,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            console.log(response, "response");

            if (response.status === 200) {
                console.log(`Comment posted successfully for task_id`);
                setComments('');
                getComment(task.id)
                toast.success('Comment posted successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                console.error(`Failed to post comment for task_id`);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div className='container'>
            <div>
                <ToastContainer theme="colored" />
                <div className='task_heading1' style={{ marginTop: "20px" }}>
                    <i className="fa fa-list" aria-hidden="true"></i>
                    <h6>Task List</h6>
                </div>
                <div className='task_list' style={{ marginTop: "20px" }}>
                    <div className='task_listhead'>
                        <table className="table _contacttable">
                            <tbody>
                                {Array.isArray(tasks) ? (
                                    tasks.map((task, index) => (
                                        <Fragment key={index}>
                                            <tr onClick={() => handleOpen(task.task)}>
                                                <th className='task_listavtar task_lsit'>
                                                    <img style={{ boxShadow: "none" }} className="avatar-img avatar-circle" alt='avtar-img' src="https:images.unsplash.com/photo-1507206130118-b5907f817163?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80" loading="lazy" />
                                                </th>
                                                <td className='task_listtext task_lsit'>
                                                    <div className='listtext1 d-grid' style={{ width: "120px" }}>
                                                        <span className=''>
                                                            {task.task.title}
                                                        </span>
                                                        <span className=''>
                                                            {task.task.description.slice(0, 15)}...
                                                        </span>
                                                    </div>
                                                    <div className='listtext1 d-grid'>
                                                        <span className=''>
                                                            {formatTime(task.task.start_time)}
                                                        </span>
                                                        <span className=''>
                                                            {formatTime(task.task.end_time)}
                                                        </span>
                                                    </div>
                                                    <label className=''>
                                                        <span>{task.task.priority === 'critical' ? <span className=' tag bg-critical-100'> Critical</span> : null}</span>
                                                        <span>{task.task.priority === 'High' ? <span className=' tag bg-red-100'> High</span> : null}</span>
                                                        <span>{task.task.priority === 'medium' ? <span className=' tag bg-medium-100'> Medium</span> : null}</span>
                                                        <span>{task.task.priority === 'low' ? <span className=' tag bg-low-100'> Low</span> : null}</span>
                                                    </label>
                                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                                </td>
                                            </tr>
                                            <tr style={{ height: "15px", boxShadow: "none" }}></tr>
                                        </Fragment>
                                    ))
                                ) : (
                                    <p>No tasks available</p>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={handleClose}>
                <ModalHeader closeButton>
                    <Modal.Title>Task Activity</Modal.Title>
                </ModalHeader>
                <ModalBody className='p-0' style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <div className="modal-body">
                        <div className='_mytask'>
                            <div className='_mytaskbody'>
                                <h5>Activity</h5>
                                <div id='list-group-item'>
                                    {Array.isArray(tasks) ? (
                                        comment.map((comm, index) => (
                                            <div className='row align-items-center list-group-item1' key={index}>
                                                {comm.user_type === "CLIENT" ? (
                                                    <>
                                                        <div className='col-2 img_col_2'>
                                                            <img className="avatar-comment avatar-circle" src={user.profile_image} style={{ float: "right" }} loading="lazy" alt='new' />
                                                        </div>
                                                        <div className='col-10 ml-n2'>
                                                            <h6 className='text-sm text-dark' style={{ border: "none" }}>{comm.user_type}</h6>
                                                            <p className='dblock text-sm'>{comm.comment}</p>
                                                            <p className="d-block">{new Date(comm.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                                        </div>
                                                    </>
                                                ) : <>
                                                    <div className='col-10 ml-n1 p-0'>
                                                        <h6 className='text-sm text-dark' style={{ border: "none" }}>{comm.user_type}</h6>
                                                        <p className='dblock text-sm1'>{comm.comment}</p>
                                                        <p className="d-block">{new Date(comm.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                                    </div>
                                                    {userDetail["mappings"] !== undefined &&
                                                        userDetail?.mappings.map((user) => {
                                                            return (
                                                                <div className='col-2' style={{ marginTop: "-30px" }} key={user.id}>
                                                                    {user.role === "LIFE_NAVIGATOR"
                                                                        ? <img className="avatar-comment avatar-circle" style={{ float: "right" }} src={user.avatar} loading="lazy" alt='new' />
                                                                        : null}

                                                                </div>
                                                            );
                                                        })}
                                                </>
                                                }
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Comments Here</p>
                                    )}
                                </div>
                                <div className='mt-4'>
                                    <h5>Comment</h5>
                                    <div className="px-3 py-2 row align-items-center">
                                        <div className="col-10 p-0 m-0" style={{ display: "flex" }}>
                                            <input
                                                type="text"
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                name="name"
                                                required=""
                                                className="form-control"
                                                placeholder="Add a Comment..."
                                            />
                                        </div>
                                        <div className='col-2 p-0 m-0'>
                                            <button onClick={handleSubmit} className='btn btn_send p-0 m-0' type='submit'>
                                                <i className="bi bi-send"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div >
    )
}


const mapStateToProps = (state) => ({
    loader: state.event.loader,
    user: state.user.userDetail,
    userDetail: state.user.userDetail,
});

export default connect(mapStateToProps)(TaskList);
// export default TaskList;