import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import FooterModal from "./Footer-Modal";
import '../main.css';


class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer-area">
                    <div id="footer-section"  className="footer-top text-center" style={{backgroundImage: "url(assets/img/bg/7.png)"}}>
                        <div className="container">
                            <p>Copyright © Averti 2020. All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="footer-bottom text-center">
                            <ul id="bottom-navigation">
                                <li>
                                    <NavLink className="home-clicked" to={'/main'} activeClassName='active'>
                                        <i className="fa fa-home fa-2-2x" />
                                        <p className="_footer-p">Home</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <div className="home-clicked __callbtn" data-toggle="modal" data-target="#__footer-madal">
                                        <i className="fa fa-phone fa-1-8x" aria-hidden="true"></i>
                                        <p className="_footer-p">Call</p>
                                    <FooterModal/>
                                    </div>
                                </li>
                                <li>
                                    <NavLink to={'/bill-pay'} className="home-clicked" activeClassName='active'>
                                        <i className="fa fa-dollar fa-1-8x" />
                                        <p className="_footer-p">Your Bills</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink exact to={'/wallet'} className="home-clicked" activeClassName='active'>
                                        <i className="fa fa-credit-card fa-1-8x" aria-hidden="true"></i>
                                        <p className="_footer-p">Wallet</p>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/user-setting'} className="home-clicked" activeClassName='active'>
                                        <i className="fa fa-user fa-1-8x" aria-hidden="true"></i>
                                        <p className="_footer-p">Profile</p>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Back to Top Area */}
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
            </>
        );
    }
}

export default Footer;