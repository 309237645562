import '../Document/document.css'
import React, { useState, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import Section from '../layouts/Section';
import Example from './CheckBox';
import { useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import auth from '../../api/auth';
import { Modal } from "react-bootstrap";
import '../prashant.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Custom-toastify.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'



import "rsuite/dist/rsuite.min.css";
import {
  Popover, Whisper, Dropdown,
  Button
} from "rsuite/";
import History from '../../History';
import AvertiLoader from '../../loader';


function Document(props) {

  const [loader, showLoader, hideLoader] = AvertiLoader()

  const updateFormRef = useRef();

  const [showModal, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [member, setMember] = useState(null);

  const handleShowModal = (member) => {
    setShow(true);
    setMember(member);
    setTimeout(() => {
      updateFormRef.current.first_name.value = member.first_name
      updateFormRef.current.last_name.value = member.last_name
      updateFormRef.current.mobile.value = member.mobile
      updateFormRef.current.email.value = member.email
    }, 1000)
  }

  const showUpdate = () => {
    setShow(true);

  }


  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {

    setImages(imageList);
  };


  const [folder_name, setFolderName] = useState('');

  const [loading] = useState(false);

  const [uid] = useState(null);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");

  const [mobile, setMobile] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [loadings, setLoadings] = useState(false);

  //..................................getfolders.................................................... 0


  const [folders, setFolders] = useState([]);
  const [reload, setReload] = useState(false);

  const getFolder = async () => {
    showLoader()
    setReload(true);

    const token = window.localStorage.getItem('AUTH_TOKEN');
    const response = await auth.get('/folders', {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
        'Accept': 'Application/json',
      },
    });
    setFolders(response.data.data);
    hideLoader()
  }
  useEffect(() => {
    getFolder();
  }, [reload]);

  //..........................addfolders............................................

  const createFolder = async (e) => {
    e.preventDefault();
    setSubmitting(true)

    try {
      const token = window.localStorage.getItem('AUTH_TOKEN');
      const folderfield = {
        folder_name: folder_name,
        members: currentSelected.current,
      }
      await auth.post('/folders', folderfield, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'Application/json',
          'Accept': 'Application/json'
        },
      })
        .then((res) => {
          setReload(value => !value);
          window.jQuery('#create-folder').modal('hide');
          toast.success('folder Added');
          setFolderName('');
        })
      await getFolder()
    }
    catch (err) {
    }
    setSubmitting(false);
  };


  //................................updatefolders..............................................

  const updatefolder = async () => {
    const folderfield = {
      folder_name: updateFolderRef.current.folder_name.value,
      members: currentSelected.current
    }
    const token = window.localStorage.getItem('AUTH_TOKEN');
    showLoader(true)
    try {



      const response = await auth.put(`/folders/${folder.id}`, folderfield, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'Application/json',
          'Accept': 'Application/json'
        },
      });
      hideLoader(false)
      hideFolder();
      setFolders(response.data.data);
      toast.success("Folder Updated")
    }
    catch (error) {
      hideFolder();
      toast.error("Api is no working")
    }
  }

  //.........................deletefolders.................................................................

  const deletefolder = async (id) => {

    if (!window.confirm('Are you sure to delete this folder?')) {
      return;
    }

    const token = window.localStorage.getItem('AUTH_TOKEN');
    const respons = await auth.delete(`/folders/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
        'Accept': 'Application/json'
      },
    });
    toast.success('folder Deleted');
    getFolder();
    // return respons.data.data;
  }


  //..............................add Documents...........................


  const [user_folder_id, setUser_folder_id] = useState();
  const handleSelect = (e) => {
    setUser_folder_id(e.target.value);
    // console.log(e.target.value);
  }
  const [file, setFile] = useState();
  function handleChange(event) {
    setFile(event.target.files[0])
    // console.log(event.target.files)
  }

  const handleUpload = async (e) => {
    e.preventDefault()

    setSubmitting(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_folder_id', user_folder_id);
    const token = window.localStorage.getItem('AUTH_TOKEN');

    auth.post('/documents', formData, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
        'Accept': 'Application/json'
      },
    }).then((response) => {
      // console.log(response.data);

      window.jQuery('#upload-files').modal('hide');
      toast.success('folder uploaded');
      setSubmitting(false);

    });
  }

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    // Validate first name
    if (first_name.trim() === '') {
      errors.first_name = 'Please enter first name';
      isValid = false;
    }

    // Validate last name
    if (last_name.trim() === '') {
      errors.last_name = 'Please enter last name';
      isValid = false;
    }

    // Validate mobile number
    if (mobile.trim() === '') {
      errors.mobile = 'Please enter mobile number';
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = 'Please enter a valid 10-digit mobile number';
      isValid = false;
    }

    // Validate email
    if (email.trim() === '') {
      errors.email = 'Please enter email';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Please enter a valid email address';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };




  //..................................add  members..........................................


  const cleanFormValues = () => {
    setFirst_name('');
    setLast_name('');
    setMobile('');
    setEmail('');
    setImages([]);
  };


  const handlesubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      showLoader()
      setSubmitting(true);
      const formData = new FormData();
      if (images.length > 0) {
        formData.append('image', images[0].file);
      }
      formData.append('first_name', first_name);
      formData.append('last_name', last_name);
      formData.append('email', email);
      formData.append('mobile', mobile);
      try {
        const token = window.localStorage.getItem('AUTH_TOKEN');
        const response = await auth.post('/members', formData, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
            'Accept': 'Application/json'
          },
        });
        window.jQuery('#createmember').modal('hide');
        toast.success('Member created');
        await getMembers();
        cleanFormValues();
        hideLoader()
      }
      catch (err) {
        hideLoader()
        if (err.response && err.response.status === 422) {
          const validationErrors = err.response.data.errors;
          Object.keys(validationErrors).forEach((field) => {
            errors[field] = validationErrors[field].join(', ');
          });
          setErrors(errors);
        } else {
          window.jQuery('#createmember').modal('hide');
        }
      }
      setSubmitting(false);
    }
  };



  //..................................  update memners............................................


  const updateAPIData = async (e) => {
    e.preventDefault();
    const form = updateFormRef.current;
    const errors = {};
    let isValid = true;
    setImages();

    if (form.first_name.value === '') {
      errors.first_name = 'Please enter first name';
      isValid = false
    } else
      if (form.last_name.value === '') {
        errors.last_name = 'Please enter last name';
        isValid = false
      } else
        if (form.mobile.value === '') {
          errors.mobile = 'Please enter mobile number';
          isValid = false
        } else if (!/^\d{10}$/.test(form.mobile.value)) {
          errors.mobile = 'Please enter a valid 10-digit mobile number';
          isValid = false;
        } else
          if (form.email.value === '') {
            errors.email = 'Please enter email';
            isValid = false;
          } else if (!/\S+@\S+\.\S+/.test(form.email.value)) {
            errors.email = 'Please enter a valid email address';
            isValid = false;
          } else {

            const formData = new FormData();
            if (images.length > 0) {
              formData.append('image', images[0].file);
            }
            formData.append('first_name', form.first_name.value);
            formData.append('last_name', form.last_name.value);
            formData.append('email', form.email.value);
            formData.append('mobile', form.mobile.value);
            showLoader()
            try {
              const token = window.localStorage.getItem('AUTH_TOKEN');
              const response = await auth.post(`/members/${member.uid}`, formData, {
                headers: {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'multipart/form-data',
                  'Accept': 'Application/json'
                },
              });

              hideLoader()
              handleClose();
              toast.success('Member Updated');
              await getMembers();
            }
            catch (err) {
              hideLoader()
              if (err.response && err.response.status === 422) {
                const validationErrors = err.response.data.errors;
                Object.keys(validationErrors).forEach((field) => {
                  errors[field] = validationErrors[field].join(', ');
                });
                setErrors(errors);
              } else {
                handleClose();
              }

            }
            setSubmitting(false);
          }
    setErrors(errors);
    return isValid;

  };



  //..........................member delete.........................


  const onDelete = async (uid) => {

    const deleteConfirmation = window.confirm('Do you want to delete?');
    if (deleteConfirmation) {

      alert("Do you want Delete?");
      setLoadings(true);
      showLoader()

      try {
        const token = window.localStorage.getItem('AUTH_TOKEN');
        const respons = await auth.delete(`/members/${member.uid}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'multipart/form-data',
              'Accept': 'Application/json'
            },
          });
        handleClose();
        toast.success('Member is deleted');
        await getMembers();
      }
      catch (err) {
        handleClose();
        toast.error('API is not working');
      }
      hideLoader()
      setLoadings(false);
    }
  };


  //.................................getallmembers.......................

  const [posts, setPosts] = useState([]);
  const getMembers = async () => {
    const token = window.localStorage.getItem('AUTH_TOKEN');
    const response = await auth.get('/members', {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
        'Accept': 'Application/json',
      },
    });
    setPosts(response.data.data);
  }
  useEffect(() => {
    getMembers();
  }, []);


  //.........................optation value.......................
  const currentSelected = useRef([]);
  const selected = (data) => {
    currentSelected.current = [];
    console.log('***', data);
    for (let option of data) {
      currentSelected.current.push(option.value);
    }
    // console.log('***', currentSelected.current);
  }





  const [folder, setFolder] = useState();
  const updateFolderRef = useRef();

  const [editSelected, setEditSelected] = useState([])

  const editFolder = (folder) => {
    setFolder(folder);
    updateFolderRef.current.folder_name.value = folder.folder_name;
    // currentSelected.current = folder.members.map(m => m.id);
    setEditSelected(folder.members.map(m => ({ label: m.name, value: m.id })))
    window.jQuery('#update-folder').modal('show');
  }

  const folderInfo = (folder) => {
    window.jQuery('#upload-file').modal('hide');
    History.push(`/information/${folder.id}`);
  }


  const showCreateMemberModal = (e) => {
    e.preventDefault();
    window.jQuery('#createmember').modal('show')
  }
  const hideCreateMemberModal = (e) => {
    e.preventDefault();
    window.jQuery('#createmember').modal('hide')
  }

  const showCreatefolder = (e) => {
    e.preventDefault();
    window.jQuery('#create-folder').modal('show');
  }
  const hideCreatefolder = (e) => {
    e.preventDefault();
    window.jQuery('#create-folder').modal('hide');
  }

  const showUploadFile = (e) => {
    e.preventDefault();
    window.jQuery('#upload-files').modal('show');
  }
  const hideUploadFile = (e) => {
    e.preventDefault();
    window.jQuery('#upload-files').modal('hide');

  }
  const showeModle = (e) => {
    e.preventDefault()
    window.jQuery('#upload-file').modal('show')
  }
  const hideFolder = () => {
    window.jQuery('#update-folder').modal('hide');

  }

  return (
    <>

      {loader}

      <Section allNotification={false} searchPopup={true} title={'Documents'} />
      <div className='h-100vh'>
        <div className="bill-pay-area">
          <div className="container">
            <div className="section-title style-three">
              <ToastContainer theme="colored" />
              <h3 className="title">Your Documents</h3>
              {/* <Link to={'/document'}><i className="fa fa-file document-file"></i></Link> */}
            </div>
          </div>
        </div>

        <div className="ba-add-balance-inner container">
          <div className="row custom-gutters-20">
            <div className="col-6 ">
              <Link className="btn btn-grey ba-add-balance-btn Main_link document" onClick={(e) => showCreateMemberModal(e)} to={'/document'}>
                <i className="fa fa-user Icon" aria-hidden="true"></i>
                Create a Member
              </Link>
              {/* Begin Centered Modal */}
              <div id="createmember" className="modal fade" >
                <div className="modal-dialog ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Create Member</h4>
                      <button type="button" className="document-close"  >
                        <span aria-hidden="true" onClick={(e) => hideCreateMemberModal(e)}>×</span>
                        <span className="sr-only">close</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form className="ui form w-100" ><div className="column grid two ui mb-1" >
                        <div className="column">
                          <div className="field">
                            <label className="ui-input-label">First Name</label>
                            <input name="first_name" required value={first_name} onChange={(e) => setFirst_name(e.target.value)} className="ui-input" placeholder="Enter first name" />
                            {errors.first_name && (<div className="invalid-feedback">{errors.first_name}</div>)}
                          </div>
                        </div>
                        <div className="column"><div className="field">
                          <label className="ui-input-label">Last Name</label>
                          <input name="last_name" required value={last_name} onChange={(e) => setLast_name(e.target.value)} className="ui-input" placeholder="Enter last name" />
                          {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                        </div>
                        </div>
                      </div>
                        <div className="field"><label className="ui-input-label">Mobile Number</label>

                          <input name="mobile" required value={mobile} onChange={(e) => setMobile(e.target.value)} maxLength={10} className="ui-input" placeholder="Enter mobile Number" />
                          {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}

                        </div>
                        <div className="field">
                          <label className="ui-input-label">Email</label>
                          <input name="email" required value={email} onChange={(e) => setEmail(e.target.value)} className="ui-input" placeholder="Enter email" />
                          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                      </form>
                    </div>
                    <div className="App">
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (

                          <div className="upload__image-wrapper" style={{ marginLeft: 15 }}>
                            <button
                              style={isDragging ? { color: 'red', } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                              className='upload__image-wrapper-btn'

                            >
                              <i className="fa fa-cloud-upload upload" style={{ marginRight: "10px" }} aria-hidden="true"></i>Choose your image
                            </button>
                            &nbsp;

                            {imageList.map((image, index) => (
                              <div key={image} className="image-item">
                                <div className="image-item__btn-wrapper">
                                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                  <img loading='lazy' src={image['data_url']} alt="avtar" width="100" />
                                </div>
                                <button onClick={() => onImageRemove(index)} className="upload__image-wrapper-btn1">Delete</button>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                    </div>

                    <div className="modal-footer" >
                      {submitting ? <Spinner animation="border" variant="primary" style={{ margin: "auto" }} /> : <button className="btn btn-primary document my-0" color='blue' onClick={handlesubmit} > <i className="fa fa-check" style={{ paddingTop: "0px" }} aria-hidden="true"></i>Save information</button>}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Centered Modal */}
            </div>

            <div className="col-6">
              <Link className="btn btn-gold ba-add-balance-btn document" onClick={(e) => showeModle(e)} to={'/about'}>
                <i className="fa fa-file document-file1"></i>
                Upload File
              </Link>
              {/* Begin Centered Modal */}
              <div id="upload-file" className="modal fade">
                <div className="modal-dialog ">
                  <div className="modal-content">
                    <div className="modal-header"><div>
                      <h4 className="modal-title">Attachments</h4><br />
                      <p className='modal-title'>Attachments you are uploaded</p>
                    </div>
                      <div style={{ display: "flex" }}>
                        <button type="button" className="document-close" onClick={(e) => showCreatefolder(e)}>
                          <i className="fa fa-plus document" aria-hidden="true"></i>
                        </button>
                        <button type="button" className="document-close" onClick={(e) => showUploadFile(e)}>
                          <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div>
                        {/* Begin Centered Modal */}
                        <div id="create-folder" className="modal fade" >
                          <div className="modal-dialog ">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title" >Create Folder</h4>
                                <button type="button" className="document-close" aria-hidden="true" >
                                  <span aria-hidden="true" onClick={(e) => hideCreatefolder(e)}>×</span>
                                  <span className="sr-only">close</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <form className="ui form w-100">
                                  <div className="column two ui mb-1">
                                    <div className="field">
                                      <label className="ui-input-label"  >Folder name</label>
                                      <input name="folder_name" onChange={(e) => setFolderName(e.target.value)} className="ui-input" value={folder_name} placeholder="Enter Folder name" />
                                    </div>
                                    <div className="field">
                                      <label className="ui-input-label">Select</label>
                                      <Example selected={selected} options={posts} />
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div className="modal-footer">
                                {submitting ? <Spinner animation="border" variant="primary" style={{ margin: "auto" }} />
                                  : <button onClick={createFolder} className="btn btn-primary document my-0" color='blue' type='submit'>
                                    <i className="fa fa-check" style={{ paddingTop: "0px" }} aria-hidden="true"></i>Save information</button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Centered Modal */}

                        {/* Begin Centered Modal */}
                        <div id="upload-files" className="modal fade">
                          <div className="modal-dialog ">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">Upload File</h4>
                                <button type="button" className="document-close">
                                  <span aria-hidden="true" onClick={(e) => hideUploadFile(e)}>×</span>
                                  <span className="sr-only">close</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <form className="ui form w-100">
                                  <div className="column two ui mb-1">
                                    <div className="field">
                                      <label className="ui-input-label">File name</label>
                                      <input name="file_name" className="ui-input" placeholder="Enter file name" />
                                    </div>
                                    <div className="field">
                                      <label className="ui-input-label">Select your Folder</label>
                                      <select name='user_folder_id' value={user_folder_id} onChange={(e) => handleSelect(e)} aria-expanded="false" className="ui-input ui selection dropdown ui-input p-0">
                                        <option className='documenet'>select </option>
                                        {
                                          folders.map((folder) => {
                                            return (< Fragment key={folder.id}>
                                              <option value={folder.id} className="document">{folder.folder_name}</option>
                                            </Fragment>)
                                          })
                                        }
                                      </select>
                                    </div>
                                  </div>
                                </form>
                                <div className="field field-section">
                                  <input type="file" name='file' className="form-control-file" onChange={handleChange} />
                                </div>
                              </div>
                              <div className="modal-footer">
                                {submitting ? <Spinner animation="border" variant="primary" style={{ margin: "auto" }} /> : <button className="btn btn-primary document my-0" onClick={handleUpload} color='blue' type='submit'> <i className="fa fa-check" style={{ paddingTop: "0px" }} aria-hidden="true"></i>Upload File</button>}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* folders */}

                      {
                        folders.map((folder, label) => {
                          return (
                            <div className='__document-section' key={label}>
                              <i className="fa fa-folder document-folder" aria-hidden="true"></i>
                              <p className="document-wife">{folder.folder_name}</p>
                              <div className='link-document'>
                                <Link to={`/information/${folder.id}`} onClick={() => folderInfo(folder)} >View</Link>
                              </div>
                            </div>
                          )
                        })
                      }
                      {/* End folders */}
                    </div>
                  </div>
                </div>
              </div>
              {/* End Centered Modal */}
            </div>
          </div>
        </div>

        <div className='hjgjh container' >
          {posts.map((item) => (
            < div key={'item' + item.id}>
              <div style={{ margin: "5px 10px 0px 10px", cursor: "pointer" }}>
                <OverlayTrigger
                  style={{ width: 40, height: 40, borderRadius: "50%" }}
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip {...props}> {item.name} </Tooltip>)}
                  placement="bottom"
                >
                  <img loading='lazy' onClick={(e) => handleShowModal(item)} src={item.profile_image} style={{ borderRadius: "50%", width: 40, height: 40, objectFit: "cover" }} alt="avtar" />
                </OverlayTrigger>
              </div>

            </div>
          ))}
        </div>

        <div className='recent-files container'>
          <h4 className='fina_nce'>Recent Documents</h4>
        </div>


        <div className='container'>
          {loading ?
            <div className='dc-shimmer'>
              <div className='dc-shimmer-item'></div>
              <div className='dc-shimmer-item'></div>
              <div className='dc-shimmer-item'></div>
              <div className='dc-shimmer-item'></div>
              <div className='dc-shimmer-item'></div>
            </div>
            : ''}
          {
            folders.map((folder) => (
              < div key={folder.id}>
                <div className='row text-center_1 px-4 py-2' >
                  <div className='col-6 text-left d-flex'>
                    <Link to={'/information/' + folder.id} >
                      <i className="fa fa-folder document-folder" style={{ fontSize: "25px" }} aria-hidden="true"></i>
                    </Link>
                    <div className='folder-title'>
                      <strong style={{ fontSize: "13px" }}>{folder.folder_name}</strong>
                      <span className='tiny-text full'>{folder.documents} {folder.documents > 1 ? 'files' : 'file'} </span>
                    </div>
                  </div>
                  <div className='col-5'>
                    {folder.members.map((member) =>
                      <OverlayTrigger
                        style={{ height: 30, width: 30 }}
                        key={member.id}
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                          <Tooltip {...props}> {member.name} </Tooltip>)}
                        placement="bottom"
                      >
                        <img loading='lazy' alt="avtar" style={{ borderRadius: "50%", height: 30, width: 30, objectFit: "cover" }} className='thumbeImage' src={member.profile_image} />
                      </OverlayTrigger>
                    )}
                  </div>
                  <div className='col-1' style={{ cursor: "pointer" }}>
                    <Whisper placement="bottomEnd"
                      trigger="click" speaker={({ left, top, className, }, ref) =>
                        <Popover ref={ref}
                          className={className}
                          style={{ left, top, }} full>
                          <Dropdown.Menu title="Create new file">
                            <Link to={'/information/' + folder.id} >
                              <Dropdown.Item eventKey={1} >
                                View
                              </Dropdown.Item>
                            </Link>
                            <Dropdown.Item eventKey={2} onClick={(e) => editFolder(folder)}>
                              Edit
                            </Dropdown.Item>

                            <Dropdown.Item eventKey={3} onClick={(e) => deletefolder(folder.id)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Popover>
                      }>
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Whisper>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        {/* member model */}


        {member !== null ?
          <Modal show={showModal} onHide={handleClose} id="updateMember">
            <Modal.Header >
              <Modal.Title>Update Member</Modal.Title>
              <button type="button" onClick={handleClose} className="document-close" data-dismiss="modal">
                <span aria-hidden="true">×</span>
              </button>


            </Modal.Header>
            <Modal.Body>

              <div className='profile-thumb'>
                <div className='set_profile'>
                  {member.profile_image ? <img loading='lazy' src={member.profile_image} alt={member.name} width="100" height="100" style={{ objectFit: 'cover', borderRadius: '50%', }} /> : null}
                </div>
              </div>


              <form ref={updateFormRef} className="ui form w-100"><div className="column grid two ui mb-1">

                <div className="column"><div className="field">
                  <label className="ui-input-label">First Name</label>

                  <input required name="first_name" className="ui-input" placeholder="Enter First name" />
                  {errors.first_name && (<div className="invalid-feedback">{errors.first_name}</div>)}

                </div>
                </div>
                <div className="column"><div className="field">
                  <label className="ui-input-label">Last Name</label>
                  <input required name="last_name" className="ui-input" placeholder="Enter Last name" />
                  {errors.last_name && (<div className="invalid-feedback">{errors.last_name}</div>)}
                </div>
                </div>
              </div>
                <div className="field"> <label className="ui-input-label">Mobile Number</label>
                  <input name="mobile" className="ui-input" placeholder="Enter Mobile Number" />
                  {errors.mobile && (<div className="invalid-feedback">{errors.mobile}</div>)}
                </div>
                <div className="field">
                  <label className="ui-input-label">Email</label>
                  <input required name="email" className="ui-input" placeholder="Enter Email" />
                  {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}
                </div>
              </form>
              <div className="App">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (

                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                        className='upload__image-wrapper-btn'

                      >
                        <i className="fa fa-cloud-upload" style={{ marginRight: "10px" }} aria-hidden="true"></i>Choose your image
                      </button>
                      &nbsp;

                      {imageList.map((image, index) => (
                        <div key={image} className="image-item">
                          <div className="image-item__btn-wrapper">
                            {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                            <img loading='lazy' src={image['data_url']} alt="avtar" width="100" />
                          </div>
                          <button onClick={() => onImageRemove(index)} className="upload__image-wrapper-btn1">Delete</button>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>

              </div>

            </Modal.Body>
            <Modal.Footer style={{ flexWrap: "nowrap" }} >
              <Button style={{ backgroundColor: "#419600", fontWeight: "600", color: "white" }} onClick={updateAPIData}>
                Update
              </Button>
              <Button style={{ backgroundColor: "red", fontWeight: "600", color: "white" }} onClick={() => onDelete(uid)}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          : ''}


        {/* updatefolder model */}
        {folders !== null ?
          <div id="update-files" className="modal fade">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Create Folder</h4>
                  <button type="button" className="document-close">
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">close</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="ui form w-100">
                    <div className="column two ui mb-1">
                      <div className="field">
                        <label className="ui-input-label">Folder name</label>
                        <input name="folder_name" onChange={(e) => setFolderName(e.target.value)} className="ui-input" value={folder_name} placeholder="Enter Folder name" />
                      </div>
                      <div className="field">
                        <label className="ui-input-label">Select members</label>
                        <Example selected={selected} options={posts} />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button onClick={updatefolder} className="btn btn-primary document my-0" color='blue' type='submit'> <i className="fa fa-check" style={{ paddingTop: "0px" }} aria-hidden="true"></i>Save information</button>
                </div>
              </div>
            </div>
          </div>
          : ''}

        <div id="update-folder" className="modal fade">
          <div className="modal-dialog">folders
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Folder</h4>
                <button type="button" className="document-close" onClick={hideFolder}>
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">close</span>
                </button>
              </div>
              <div className="modal-body">
                <form ref={updateFolderRef} className="ui form w-100">
                  <div className="column two ui mb-1">
                    <div className="field">
                      <label className="ui-input-label">Folder name</label>
                      <input name="folder_name" className="ui-input" placeholder="Enter Folder name" />
                    </div>
                    <div className="field">
                      <label className="ui-input-label">Select</label>
                      {folders.length > 0 && folder !== undefined ?
                        <Example selected={selected} defaultValue={editSelected} options={posts} />
                        : ''}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button onClick={updatefolder} className="btn btn-primary document my-0" color='blue' type='submit'> <i className="fa fa-check" style={{ paddingTop: "0px" }} aria-hidden="true"></i>Update Folder</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Document