import React from 'react'
import Footer from './layouts/Footer';
import Section from './layouts/Section';
import { Link } from 'react-router-dom';
import CashFlow from '../icons/cashflow.png';
import Estate from '../icons/estate.png';


function FinanceReport() {
    return (
        <div>
            <Section allNotification={false} searchPopup={true} title={"Finance Report"}>
                <div className="mainreport mb-4 mt-4">
                    <div className="mainpagehead">
                        <div className="container">
                            <div className="row mainpagerow">
                                <ul className='mainpageul' style={{marginTop:"50px"}}>
                                    <li className='mainpageli'>
                                        <Link className="ba-add-balance-btn" to={'/estate-report'}>
                                            <img loading='lazy' src={Estate} alt={Estate} className="healthIcon" />
                                            <h6 className='ta-center'>Estate Planning</h6>
                                        </Link>
                                    </li>

                                    <li className='mainpageli'>
                                        <Link className="ba-add-balance-btn" to={'/flow-report'}>
                                            <img loading='lazy' src={CashFlow} alt={CashFlow} className="stocksIcon" />
                                            <h6 className='ta-center'>CashFlow</h6>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Footer />
        </div>
    )
}

export default FinanceReport;